.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.canvas-overlay {
    position: absolute;
    top: 0;
    left: 0;
    min-width: calc(100% - 16px);
    min-height: 100%;
    opacity: 0.9;
    backdrop-filter: blur(10px);
}

.canvas-loading-indicator-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.canvas-loading-indicator {
    background-color: #dedede;
    width: 100px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    border-radius: 10px;
}