.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(80vh);
    padding-top: .5rem;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }

  .sidebar-sticky-lut {
    position: relative;
    top: 0;
    height: calc(30vh);
    padding-top: .5rem;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }